// EnhancedSuccessPage.js
import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaBoxOpen, FaCheckCircle } from 'react-icons/fa';
import { useLocation, useSearchParams } from 'react-router-dom';

const EnhancedSuccessPage = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const paymentMethod = searchParams.get('paySystemName'); 
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center py-5" style={{ minHeight: '100vh', backgroundColor: '#f8f9fa',marginTop:'55px' }}>
      {/* Success Message Card */}
      <Card className="text-center shadow border-0" style={{ maxWidth: '500px', borderRadius: '10px' }}>
        
      {
            paymentMethod=="cashOnDelivery"? <Card.Body>
            {/* Icon and Message */}
            <FaBoxOpen className="text-primary display-1 mb-4" />
            <Card.Title className="h3 fw-bold">Order Placed Successfully!</Card.Title>
            <Card.Text className="text-muted">
              Thank you for your order. Your items will be delivered soon. You can track your order
              status or continue shopping for more great products!
            </Card.Text>
          </Card.Body>:   <Card.Body>
         
         {/* Icon and Message */}
         <FaCheckCircle className="text-success display-1 mb-4" />
         <Card.Title className="h3 fw-bold">Payment Successful!</Card.Title>
         <Card.Text className="text-muted">
           Thank you for your purchase. Your order has been placed successfully. You can track your order status or continue shopping for more great items!
         </Card.Text>
       </Card.Body>
          }
     

        
      </Card>

      {/* Action Buttons */}
      <Row className="mt-4 text-center">
        <Col>
          <Button variant="outline-primary" className="me-3 px-4 py-2" href="https://dashboard.ownfood.com.bd/" style={{ borderRadius: '30px' }}>
            Track Order
          </Button>
          <Button variant="primary" className="px-4 py-2" href="/" style={{ borderRadius: '30px' }}>
            Continue Shopping
          </Button>
        </Col>
      </Row>

      {/* Contact Information */}
      <Row className="mt-4 text-center">
        <Col>
          <p className="text-muted">
            Need assistance?{' '}
            <a href="/" className="text-decoration-none fw-semibold text-primary">
              Contact our support team.
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default EnhancedSuccessPage;
